<template>
  <div class="benefit-detail">
    <nav-header></nav-header>
    <div class="benefit-detail-header">
      <div class="benefit-detail-title">{{ title }}</div>
    </div>
    <div class="benefit-detail-content">
      <template v-if="images && images.length">
        <img v-for="(item, index) in images" :key="index" :src="item" />
      </template>
      <div v-else style="text-align: center; margin: 0.8rem 0">暂无内容</div>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader";
export default {
  name: "BenefitDetail",
  components: { NavHeader },
  data() {
    return {
      title: "",
      images: [],
    };
  },
  created() {
    this.title = this.$route.params.title || "--";
    this.images = this.$route.params.images || [];
  },
};
</script>
<style lang="scss" scoped>
.benefit-detail {
  font-size: 0.26rem;
  position: relative;
  margin-top: 0.45rem;
  display: inline-block;
  width: 100%;
  .benefit-detail-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .benefit-detail-title {
      box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.18);
      line-height: 0.5rem;
      padding: 0 0.15rem;
      @include text-overflow;
    }
  }

  .benefit-detail-content {
    width: 100%;
    margin-top: 0.5rem;
    img {
      width: 100%;
    }
  }
}
</style>